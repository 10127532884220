import React from "react";
import VimeoWithData from "../components/vimeo";
import VimeoThumbWithData from "../components/vimeo-thumb";
import arrow from "../images/left-top-arrow.svg"

console.log(arrow)

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  handleKeyPress = event => {
    if (event.key == "Enter") {
      console.log("enter press here! ");
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", (e) => {
      if (e.code == "Escape") {
        this.setState ({
          isToggleOn: false
        })
      }
    });
  }

  render() {
    return (
      <div className="relative">
        {this.state.isToggleOn && (
          <div className="w-full h-full fixed pin bg-black z-50">
            <div
              className="fixed z-50 pin-t pin-x text-center pt-2 text-white cursor-pointer"
              onClick={this.handleClick}
            >
              CLOSE
            </div>
            <VimeoWithData url={this.props.data.video_url} />
          </div>
        )}
        
        
        <div
          className="w-full h-full absolute pin cursor-pointer action z-40"
          onClick={this.handleClick}
        ></div>
        <VimeoThumbWithData url={this.props.data.preview_url} />
        <div className="absolute right-0 pt-2 cursor-pointer" onClick={this.handleClick}><img src={arrow} className="h-4"/> Play</div>
      </div>
    );
  }
}

export default Video;
