import React from "react";
const data = require("../data/info.json");

export default () => {
  console.log(data.clients);
  return (
    <div className="font-mono leading-relaxed mb-16">
      <div className="flex">
        <div className="w-full md:w-2/3 lg:w-1/3 p-2">
            <div className="p-3 border">
          Emmanuel Mauriès-Rinfret
          <br />
          Filmmaker - Art Director
          <br />
          <br />
          Get in touch:
          <br />
          <a href="mailto:hello@emmanuelmr.com">Email</a>
          <br />
          <a href="https://www.instagram.com/jim_teeple/">Instagram</a>
          <br />
          <a href="https://vimeo.com/emmanuelmr">Vimeo</a>
        </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/3 p-2">
          <div className="p-3 border">
            <div className="underline mb-3">Comissionned work</div>
            {data.clients.map((name, index) => (
              <div key={index} className="">
                {name}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full md:w-1/3 p-2">
          <div className="p-3 border">
            <div className="underline mb-3">Editing clients [international]</div>
            {data.commercial_client.map((name, index) => (
              <div key={index} className="">
                {name}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full md:w-1/3 p-2">
          <div className="p-3 border">
            <div className="underline mb-3">Editing clients [local]</div>
            {data.commercial_client_local.map((name, index) => (
              <div key={index} className="">
                {name}
              </div>
            ))}
          </div>
        </div>
        
      </div>
    </div>
  );
};
