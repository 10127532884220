import React from "react";
import VimeoThumbWithData from "../components/vimeo-thumb";
import Video from "../components/video";
import Image from "../components/img";
import LazyLoad from 'react-lazyload';

const data = require("../data/feed.json");

export default () => {
  return (
    <div>
      {data.map((o, index) => (
        <div key={index}  className="mb-64">
          { o.type == 'image' ?
            <Image src={o.img_url} />
          :
            <LazyLoad height={400} offset={2000}>
              <div>
              {o.type == 'gif-video' &&
                <VimeoThumbWithData url={o.preview_url} />
              }
              {o.type == 'video' &&
                <Video data={data[index]}/>
              }
              </div>
              
              <div className="font-mono">
                <div className="pt-2 underline">
                  {o.title}
                </div>
              </div>
              
            </LazyLoad>
          }
        </div>
        
      ))}
      
    </div>
  );
};
