import React from "react";
import withFetch from "./vimeo-w-fetch";

//DOM RENDER
const VimeoThumb = ({ data }) => {
  const url = `https://player.vimeo.com/video/${data.video_id.toString()}?autoplay=1&amp;color=fff&amp;title=0&amp;byline=0&amp;portrait=0`;
  
  
  return (
    <div>
      <iframe
        src={url}
        frameBorder="0"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowFullScreen
        width="100%"
        height="100%"
        className="absolute pin"
      />
    </div>
  );
};

//Gets vimeo url from prop
//Vimeo API fetch
const VimeoWithData = url => {
  const VimeoWithData = withFetch(VimeoThumb, url.url);
  return <VimeoWithData />;
};

export default VimeoWithData;
