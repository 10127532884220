import React from "react";
import withFetch from "./vimeo-w-fetch";


//DOM RENDER

const VimeoThumb = ({ data }) => {  
  return (
        <PositionLabel data={data}/>
  );
};

const PositionLabel = (props) => {
  const {
    detectedEnvironment: {
      isMouseDetected = false,
      isTouchDetected = false
    } = {},
    elementDimensions: {
      width = 0,
      height = 0
    } = {},
    isActive = false,
    isPositionOutside = false,
    position: {
      x = 0,
      y = 0
    } = {},
    data
  } = props;

  const url = `https://player.vimeo.com/video/${data.video_id.toString()}?background=1&amp;mute=0&amp;autoplay=1&amp;loop=1&amp;color=ff5900&amp;title=0&amp;byline=0&amp;portrait=0`
  const aspect = data.height/data.width
  const divStyle = {
    paddingBottom: `${aspect*100}%`
  };
  let titleStyle = {
    
    left: `${x}px`,
    top: `${y}px`
  };

  return (
    <div>
      
        <div style={divStyle} className="w-full h-24 relative loading bg-no-repeat bg-center overflow-hidden cursor-move">
          <div className="absolute pin">            
          </div>
          <iframe
            src={url}
            frameBorder="0"
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowFullScreen
            width="100%"
            height="100%"
            className="absolute pin z-30"
          />
        </div>      
    </div>
  );
};

//Gets vimeo url from prop
//Vimeo API fetch
const VimeoThumbWithData = url => {
  const VimeoThumbWithData = withFetch(VimeoThumb, url.url);
  return (
      <VimeoThumbWithData />
  );
};

export default VimeoThumbWithData;

