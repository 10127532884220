import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FEED from "../components/feed";
import INFOS from "../components/infos";
import { Helmet } from "react-helmet"

function IndexPage() {
  return (
    <Layout>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Inconsolata" rel="stylesheet"/>
      </Helmet>
      <SEO
        title="Emmanuel Mauriès-Rinfret"
        keywords={[`Emmanuel`, `Mauriès-Rinfret`, `Director`, `Art Designer`, `Artist`]}
      />
      
      <FEED />
      <INFOS />

    </Layout>
  );
}

export default IndexPage;
